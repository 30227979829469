

.cta-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 2.2vw;
  font-weight: 700;
  line-height: 2.8vw;
  text-decoration: none;
  color: #0032C7;
  cursor: pointer;
  span {
    width: auto;
    padding-bottom: 1px;
    border-bottom: 3px solid #F4F4CD;
    transition: border .8s .2s, padding 1s;
  }
  &:hover {
    span {
      transition: border .3s, padding 1s;
      border-color: #0032C7;
      padding-right: 5vw;
    }
  }
}
.cta-button {
  @media only screen and (max-width: $tablet) {
    font-size: 21px;
    line-height: 27px;
  }
}

.main-navigation {
  position: fixed;
  right: 35px; top: 10px;
  z-index: 100;
  @media only screen and (max-width: $tablet) {
    top: -5px;
  }
  @media only screen and (max-width: $mobile-big) {
    right: 15px;
  }
  ul {
    display: flex;
    list-style: none;
    padding: 0;
    gap: 22px;
    @media only screen and (max-width: $tablet) {
      gap: 15px;
    }
    li {
      font-size: 20px;
      color: rgba($color: #000000, $alpha: .3);
      @media only screen and (max-width: $tablet) {
        font-size: 16px;
      }
      a {
        text-decoration: none;
        font-weight: 600;
        color: #0032C7;
      }
    }
  }
}

.main-header {
  .introduction {
    padding: 7vw 2vw 12vw 2vw;
    box-sizing: border-box;
    @media only screen and (max-width: $mobile-big) {
      padding-left: 13vw;
      padding-bottom: 7vw;
    }
    @media only screen and (max-width: $mobile-small) {
      padding-left: 2vw;
      padding-top: 15vw;
    }
  }
  .main-title {
    display: flex;
    font-size: 3vw;
    line-height: 4.6vw;
    font-weight: 600;
    margin: 2vw 0 2vw 0;
    @media only screen and (max-width: $tablet) {
      font-size: 33px;
      line-height: 44px;
    }
    @media only screen and (max-width: $mobile-big) {
      flex-direction: column;
      line-height: 40px;
      margin: 4vw 0 4vw 0;
    }
  }
  .main-paragraph {
    font-size: 1.7vw;
    line-height: 2.5vw;
    font-weight: 300;
    margin: 0 0 2vw 0;
    @media only screen and (max-width: $tablet) {
      font-size: 18px;
      line-height: 26px;
    }
    @media only screen and (max-width: $mobile-big) {
      margin: 0 0 4vw 0;
    }
  }
  .profile-photo {
    position: absolute;
    top: 0px; left: -7vw;
    width: 35vw;
    background: url(../../public/img/photo-reneooms.webp) no-repeat top left;
    background-size: 100%;
    padding-bottom: 100%;
    @media only screen and (max-width: $mobile-big) {
      top: 30px;
      left: -18vw;
    }
    @media only screen and (max-width: $mobile-small) {
      display: none;
    }
  }
  
}



.viewer-component {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: $default-tile-radius;
  transform: translate(0,0);
  opacity: 0;
  &.reveal {
    animation: reveal-anim 2s both cubic-bezier(0,1.01,0,1);
  }
  .viewer-item, .viewer-loader {
    position: absolute;
    top: 0px; left: 0px;
    width: 100%;
    overflow: hidden;
    transform: translate(0,0);
    &.selected {
      animation: 
        partial-reveal-in-anim .5s cubic-bezier(0,1.01,0,1) both,
        simple-fade-in .2s ease-out both;
    }
    .viewer-image {
      width: 100%;
    }
  }
  .viewer-loader {
    background-color: #E9F5F5;
    width: 100%; height: 100%;
    z-index: 100;
    transition: opacity .5s;
    &.loaded {
      opacity: 0;
    }
  }
  .viewer-controll {
    z-index: 200;
    position: absolute;
    bottom: 10px; right: 10px;
    .controll-background, .controll-progress {
      stroke-width: 10px;
      fill: none;
      stroke: white;
      opacity: .3;
    }
    .controll-background {
      opacity: .3;
    }
    .controll-progress {
      stroke-dasharray: 66px, 66px;
      stroke-dashoffset: 30px;
    }
  }
}

.player-component {
  transform: translate(0,0);
  opacity: 0;
  color: white;
  background-color: white;
  &.reveal {
    animation: reveal-anim 2s both cubic-bezier(0,1.01,0,1);
  }
  .player-video {
    width: 100%;
    border-radius: $default-tile-radius;
    border: none;
    color: white;
    background-color: white;
  }
}

@keyframes reveal-anim {
  0% {
    opacity: 0;
    transform: translateY(800px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes partial-reveal-in-anim {
  0% {
    transform: translate(0,10%);
  }
  100% {
    transform: translate(0,0);
  }
}
@keyframes simple-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.service-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  li {
    font-size: 1.8vw;
    font-weight: 500;
    border-bottom: 1px solid rgba($color: #000000, $alpha: .1);
    padding: 1.2vw 0;
    @media only screen and (max-width: $tablet) {
      font-size: 20px;
      padding: 2.5vw 0;
    }
  }
}

.portfolio {
  margin-bottom: 10vw;
}
.services {
  background-color: #F4F4CD;
  padding: 3vw 0 5vw 0;
  .grid-container {
    align-items: center;
    @media only screen and (max-width: $mobile-big) {
      align-items: start;
      padding-bottom: 5vw;
    }
  }

  .content-wrap {
    @media only screen and (max-width: $mobile-big) {
      padding: 0;
    }
  }
  .grid-item.col-5 {
    width: 90%;
    margin-bottom: 0;
  }
}
.about {
  padding: 3vw 0 10vw 0;
  background-color: black;
  color: white;
  .grid-container {
    align-items: center;
    @media only screen and (max-width: $tablet-small) {
      flex-direction: column;
    }
  }
  p {
    padding-right: 7vw;
    &.extra-attention {
      @media only screen and (min-width: $tablet-big) {
        padding-right: 15vw;
      }
    }
  }
  .cta-button {
    color: #22B7FF;
    font-size: 24px;
    span {
      border-color: rgba(255,255,255,.15);
    }
    &:hover {
      span {
        border-color: #22B7FF;
      }
    }
  }
  .content-wrap {
    @media only screen and (max-width: $tablet) {
      padding-right: 0;
    }
    @media only screen and (max-width: $mobile-big) {
      padding: 0 0 10vw 0;
    }
  }

  .doyle-insert {
    @media only screen and (max-width: $tablet-small) {
        padding-top: 10vw;
        padding-left: 5.5vw;
    }
    @media only screen and (max-width: $mobile-big) {
      padding: 0;
    }
  }
}

@media only screen and (max-width: $tablet-small) {
  .services, .about {
    .grid-container {
      [class*="col-"] {
        flex-basis: 100%;
        max-width: 100%;
      }
      [class*="col-offset-"] {
        margin-left: 0;
      }
    }
  }
}
.main-footer {
  text-align: center;
  background-color: black;
  color: rgba($color: #ffffff, $alpha: .3);
  padding: 5vw;
}