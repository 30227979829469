
@font-face {
	font-family: 'HCo Verlag';
	src:url('../../public/fonts/woff2/Verlag-XLight_Web.woff2') format('woff2'),
		url('../../public/fonts/woff/Verlag-XLight_Web.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Verlag';
	src:url('../../public/fonts/woff2/Verlag-XLightItalic_Web.woff2') format('woff2'),
		url('../../public/fonts/woff/Verlag-XLightItalic_Web.woff') format('woff');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'HCo Verlag';
	src:url('../../public/fonts/woff2/Verlag-Light_Web.woff2') format('woff2'),
		url('../../public/fonts/woff/Verlag-Light_Web.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Verlag';
	src:url('../../public/fonts/woff2/Verlag-LightItalic_Web.woff2') format('woff2'),
		url('../../public/fonts/woff/Verlag-LightItalic_Web.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'HCo Verlag';
	src:url('../../public/fonts/woff2/Verlag-Book_Web.woff2') format('woff2'),
		url('../../public/fonts/woff/Verlag-Book_Web.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Verlag';
	src:url('../../public/fonts/woff2/Verlag-BookItalic_Web.woff2') format('woff2'),
		url('../../public/fonts/woff/Verlag-BookItalic_Web.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'HCo Verlag';
	src:url('../../public/fonts/woff2/Verlag-Bold_Web.woff2') format('woff2'),
		url('../../public/fonts/woff/Verlag-Bold_Web.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Verlag';
	src:url('../../public/fonts/woff2/Verlag-BoldItalic_Web.woff2') format('woff2'),
		url('../../public/fonts/woff/Verlag-BoldItalic_Web.woff') format('woff');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'HCo Verlag';
	src:url('../../public/fonts/woff2/Verlag-Black_Web.woff2') format('woff2'),
		url('../../public/fonts/woff/Verlag-Black_Web.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Verlag';
	src:url('../../public/fonts/woff2/Verlag-BlackItalic_Web.woff2') format('woff2'),
		url('../../public/fonts/woff/Verlag-BlackItalic_Web.woff') format('woff');
	font-weight: 800;
	font-style: italic;
}

h2 {
  font-weight: 300;
  font-size: 5vw;
  &.ghost {
    position: absolute;
    visibility: hidden;
  }
  @media only screen and (max-width: $tablet) {
    font-size: 40px;
  }
}
p {
  font-weight: 300;
  font-size: 22px;
  line-height: 28px;
  margin-top: 0;
  margin-bottom: 24px;
  &.extra-attention {
    font-size: 26px;
    line-height: 36px;
    @media only screen and (max-width: $mobile-small) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  @media only screen and (max-width: $mobile-small) {
    font-size: 18px;
    line-height: 26px;
  } 
}