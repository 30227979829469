html {
  scroll-behavior: smooth;
}
body {
  font-family: 'HCo Verlag';
}

.site-container {
  
}
.section-container {
  
}

@for $i from 1 through $grid-columns {
  .col-#{$i} {
    box-sizing: border-box;
    flex-basis: calc(100% / $grid-columns * $i);
    max-width: calc(100% / $grid-columns * $i);
  }

  .col-offset-#{$i} {
    margin-left: calc(100% / $grid-columns * $i);
  }
}

.grid-container {
  position: relative;
  display: flex;
  align-items: flex-start;
  //gap: 2.566096423%;
  gap: 23px;
  width: calc(100vw - 70px);
  margin: 0 auto;
  @media only screen and (max-width: $tablet) {
    gap: 2.566096423%;
  }
  @media only screen and (max-width: $mobile-small) {
    width: calc(100vw - 30px);
  }
}

.content-wrap {
  padding: 2vw 5vw;
}

.spacing-2 {
  margin-top: 5.5%;
}

.spacing-3 {
  margin-top: 8.3%;
}

.spacing-4 {
  margin-top: 11%;
}

.spacing-6 {
  margin-top: 16.6%;
}
.spacing-10 {
  margin-top: 25%;
}

@media only screen and (max-width: $mobile-big) {
  .grid-container:not(.ignore-mobile) {
    [class*="col-"] {
      margin-left: 5%;
      flex-basis: 90%;
      max-width: 90%;
      margin-bottom: 6vw;
    }
    flex-direction: column;
    align-items: stretch;
    
  }
  [class*="col-"] {
    flex-basis: 100%;
    max-width: 100%;
  }
  [class*="col-offset-"] {
    margin-left: 0;
  }
  [class*="spacing-"] {
    margin-top: 0;
  }
  .ignore-mobile {
    margin-bottom: 6vw;
  }
}

@media only screen and (max-width: $mobile-small) {
  .grid-container:not(.ignore-mobile) {
    [class*="col-"] {
      margin-left: 5%;
      flex-basis: 90%;
      max-width: 90%;
    }
  }
}

/*
.grid-item {
    
}

.portfolio-row {
  
}
*/

